@use "../global" as g;


body.error404 {
  --mainColor: #{g.$color_magenta};
}

.bl_error {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_title {
    text-align: center;
    font-size: clamp(1rem, g.vw(40px), 3em);
    line-height: 1.3em;
    color: var(--mainColor);

    span {
      display: block;
      font-size: 4em;
      line-height: 1em;
    }
  }
}