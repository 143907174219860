@use "../global" as g;

#header {
  // カラー変更に使用
  --mainColor: #{g.$color_black};
  --subColor: #{g.$color_white};
  //サイズ変更に使用
  --baseFontSize: #{g.rem(15.5px)};

  --paddingTop: #{g.rem(12px)};
  --paddingBottom: #{g.rem(12px)};
  --logoWidth: 200px;

  @include g.mq(max, md) {
    --logoWidth: 140px;
    --paddingTop: 3vh;
  }

  ;

  // 白文字表記
  &.white {
    --mainColor: #{g.$color_white};
    --subColor: #{g.$color_black};
  }

  // largeサイズ時の数値
  &.large {
    @include g.mq(min, md) {

      --baseFontSize: #{g.rem(18.5px)};
      --logoWidth: 300px;
      --paddingTop: #{g.rem(30px)};
      --paddingBottom: #{g.rem(30px)};
    }

    // @include g.mq(max, md) {
    //   --baseFontSize: #{g.rem(10.5px)};
    //   --logoWidth: 150px;
    //   --paddingTop: #{g.rem(10px)};
    //   --paddingBottom: #{g.rem(10px)};
    // }

  }



  position: fixed;
  z-index: g.$zindex_header;
  width: 100%;
  // background-color: rgba(g.$color_orange, .5);
  font-family: g.$fontset_heading;
  font-weight: bold;
  font-size: var(--baseFontSize);
  color: var(--mainColor);
  transition-duration: .5s;
  transition-property: font-size,
  color,
  width,
  margin;

  .container {

    padding-top: var(--paddingTop);
    padding-bottom: var(--paddingBottom);
    padding-right: g.vw(50px, xxl);
    padding-left: g.vw(50px, xxl);
    transition: padding 1s;

    display: flex;
    justify-content: space-between;
    align-items: center;


    .header_logo {
      width: var(--logoWidth);
      fill: var(--mainColor);
      transition-duration: .5s;
      transition-property: width, fill;

      // stroke-width: .2px;
      // stroke: g.$color_black;
      // fill: transparent;
      // /* アニメーションの名前 */
      // animation-name: dash-animation;
      // /* アニメーションが完了するまでの所要時間 */
      // animation-duration: 4s;
      // animation-fill-mode: forwards;
      // /* アニメーションを永遠に繰り返す */
      // // animation-iteration-count: infinite;
    }

    @keyframes dash-animation {
      0% {
        /* 線分0、間隔50の破線にする */
        stroke-dasharray: 0 50;
        fill: transparent;
      }

      50% {
        /* 線分50、間隔0の破線にする */
        stroke-dasharray: 50 0;
        stroke-width: .3px;
        fill: transparent;
      }

      100% {
        stroke-width: 0;
        fill: g.$color_black;
      }
    }

    nav {
      ul {
        display: flex;
        align-items: center;
        margin: unset;
        padding: unset;
        list-style: none;

        li {
          margin-left: .2em;
          position: relative;

          // SP時にハンバーガー以外を非表示
          &:not(.hamburger) {
            @include g.mq(max, md) {
              display: none;
            }
          }

          a {
            $mainColor: var(--mainColor);
            display: inline-block;
            color: var(--mainColor);
            padding: 0.5em .7em;
            border: 1px solid rgba(0, 0, 0, 0);
            transition: border-radius .3s, border-color .3s;
          }


          // ホバー時の枠線の指定
          &:not([class]) {

            a:hover {
              border: 1px solid var(--mainColor);
              border-radius: 3em;
            }
          }


          // &.contact a {
          //   color: var(--subColor);
          //   background-color: var(--mainColor);
          //   border: 1px solid var(--mainColor);
          //   border-radius: 3em;
          //   transition-property: color, background-color;
          //   transition-duration: .3s;

          //   &:hover {
          //     color: var(--mainColor);
          //     background-color: var(--subColor);
          //   }
          // }

          &.hamburger {
            // margin-left: 1em;

            a {
              position: relative;
              top: 0;
              right: 0;
              z-index: g.$zindex_header_hamburger;
              border: 1px solid var(--mainColor);
              border-radius: 3em;
              display: flex;
              justify-content: center;
              align-items: center;
              transition-property: top, right;
              transition-duration: .5s;
              padding: 1.2em 2em;

              span,
              span::before,
              span::after {
                content: '';
                display: block;
                height: 1.5px;
                width: 1.2em;
                background-color: var(--mainColor);
                border-radius: 1rem;
                position: absolute;
                transition-property: transform, top, bottom, background-color;
                transition-duration: .5s;
              }

              span::before {
                bottom: .35em;
              }

              span::after {
                top: .35em;
              }

              @media (hover: hover) {

                &:hover {
                  transform: scale(1.1);

                  span::before {
                    transform: translateY(-150%);
                  }

                  span::after {
                    transform: translateY(150%);
                  }
                }
              }

            }

            &.js_active {
              >a {
                top: 6em;
                right: 2em;

                >span {
                  background-color: rgba(#fff, 0);

                  &::before {
                    transform: rotate(45deg);
                    bottom: 0;
                  }

                  &::after {
                    transform: rotate(-45deg);
                    top: 0;
                  }
                }
              }

              
            }
          }
        }
      }
    }
  }
}

#header_modal {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 3em;
  border: 1px solid g.$color_black;
  // border: 1px solid rgba(g.$color_black, .8);
  border-radius: 2rem;
  // background-color: rgba($color: #000, $alpha: .5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  transform: scale(.5) translate(50%, -50%);
  transition-property: top, right, opacity, visibility, transform;
  transition-duration: .5s;
  will-change: transform;

  >p {
    margin-bottom: 2rem;
    font-size: 1rem;
  }

  >ul {
    line-height: 2em;
    font-family: g.$fontset_heading;
    font-weight: bold;
    font-size: 2rem;

    li {
      a {
        color: g.$color_black;
        &:hover {
          opacity: .5;
        }
      }
    }
  }

  &.white {
    color: g.$color_white;
    border: 1px solid g.$color_white;

    ul {
      li {
        a {
          color: g.$color_white;
        }
      }
    }
  }

  &.js_active {
    visibility: visible;
    opacity: 1;
    top: 6.3em;
    right: 5%;
    transform: scale(1) translate(0, 0);
  }


}