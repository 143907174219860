@use "../global" as g;

footer {
  background-color: g.$color_white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  // @include g.mq(max, md) {
  //   justify-content: space-between;
  //   min-height: 100vh;
  // }

  .separation {
    clip-path: inset(0);
    overflow: hidden;
    font-size: clamp(g.rem(8px), g.vw(18px, xl), g.rem(24px));
    padding: 4em 0;
    cursor: default;

    .footer_bgImg {
      position: fixed;
      top: 0;
      height: 100vh;
      width: 100vw;
      background-image: url('../img/footer_scroll_back.webp');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      // background-attachment: fixed;
    }

    .scroll_text {
      display: flex;
      font-family: g.$fontset_scrollText;
      line-height: .6em;
      font-weight: 800;
      -webkit-text-stroke: 1px g.$color_grey;
      color: rgba($color: #000000, $alpha: 0);
      will-change: transform;
      white-space: nowrap;

      &.scroll_text_top {
        font-size: 6em;

        div {
          animation: loopText 30s linear infinite;
        }
      }

      &.scroll_text_bottom {
        font-size: 10em;

        div {
          animation: loopText 25s linear infinite;
        }
      }

      div {
        padding-right: .2em;

        // span.red {
        //   -webkit-text-stroke-color: g.$color_red;
        // }
        // span.yellow {
        //   -webkit-text-stroke-color: g.$color_yellow;
        // }
        // span.green {
        //   -webkit-text-stroke-color: g.$color_green;
        // }
        // span.cyan {
        //   -webkit-text-stroke-color: g.$color_cyan;
        // }
        // span.purple {
        //   -webkit-text-stroke-color: g.$color_purple;
        // }
        // span.magenta {
        //   -webkit-text-stroke-color: g.$color_magenta;
        // }
      }

      @keyframes loopText {
        0% {
          transform: translateX(0%);
        }

        100% {
          transform: translateX(-100%);
        }
      }
    }
  }

  .footer_main {
    --footerMainColor: #{g.$color_grey};

    font-size: clamp(g.rem(14px), g.vw(16px, xl), g.rem(18px));
    color: g.$color_grey;

    .container {
      display: flex;
      position: relative;
      padding-top: 20vh;
      padding-bottom: 20vh;

      @include g.mq(max, md) {
        flex-direction: column;
        // padding-top: unset;
        // padding-bottom: unset;
      }

      div:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 9em;

        .footer_logo {
          width: 13em;
          fill: var(--footerMainColor);
        }

        p {
          font-size: .8em;
          letter-spacing: .00em;
        }
      }

      div:nth-child(2) {
        padding-left: 5em;
        line-height: 2em;
        border-left: 1px solid #e6e4d9;

        @include g.mq(max, md) {
          margin-top: 4em;
          padding-left: unset;
          border-left: none;
        }

        a {
          color: inherit;

          &:hover {
            color: g.$color_black;
          }
        }

        .privacy {
          margin-top: 2em;
        }
      }

    }
  }

  .toTop {
    $size: 7rem;
    $allowWidth: 20%;
    $allowColor: g.$color_grey;
    $allowColorHover: g.$color_white;

    display: block;
    position: absolute;
    left: 50%;
    bottom: 0%;
    width: $size;
    height: $size;
    // border: 1px solid $allowColor;
    background-color: g.$color_lightgrey;
    border-radius: $size;
    will-change: transform;
    transition-property: transform, background-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    transform: translate(-50%, 50%);

    span {
      display: inline-block;
      height: $allowWidth;
      width: $allowWidth;
      position: absolute;
      top: 30%;
      left: 50%;
      border-width: 1px 0 0 0;
      border-style: solid;
      border-color: $allowColor;
      will-change: transform;
      transition-property: transform, border-color;
      transition-duration: .2s;
      transition-timing-function: ease-in-out;
      transform: translate(-50%, -40%);

      &::before {
        content: '';
        display: block;
        width: 50%;
        height: 50%;
        border-width: 1px 1px 0 0;
        border-style: solid;
        border-color: $allowColor;
        position: absolute;
        // top: 20%;
        left: 50%;
        transform: translate(-50%, 30%) rotate(-45deg);
      }
    }

    &:hover {
      transform: translate(-50%, 40%);

      >span {
        transform: translate(-50%, -60%);
      }
    }
  }
}