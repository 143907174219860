@use "../../global" as g;

.el_button {
  $mainColor: g.$color_black;
  $subColor: g.$color_white;

  color: $mainColor;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid $mainColor;
  border-radius: 3em;
  padding: .5em 1.5em;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: color, background-color;

  &:hover {
    background-color: $mainColor;
    color: $subColor;
  }

  // &:active {
  //   top: $btnStroke;
  //   margin-bottom: $btnStroke;
  //   border-bottom: 0px solid darken($btnColor, 13%);
  // }
}