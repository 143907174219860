@use '../global/' as g;

// load時にアニメーションが動かないようにする
// loader.jsで終了時にremove
.preload * {
  transition: none !important;
}

#loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: g.$zindex_loader;
  background-color: g.$color_white;


  &.active {
    display: flex;
  }

  //訪問済みならロゴとか表示しない
  &.visited {
    svg,
    .spinner {
      display: none;
    }
  }

  svg {
    fill: g.$color_cyan;
    width: min(60vw, 30em);
    // width: 15em;
    stroke-width: .3px;
    transform: translateY(-100%);
    // border: 1px dashed #f00;
    
      

      &.draw {
        stroke: g.$color_black;
        stroke-width: 0;
        fill: transparent;
        /* アニメーションの名前 */
        animation-name: dash-animation;
        animation-delay: .5s;
        animation-duration: 5s;
        animation-fill-mode: forwards;
        /* アニメーションを永遠に繰り返す */
        // animation-iteration-count: infinite;
      }
      @keyframes dash-animation {
        0% {
          /* 線分0、間隔50の破線にする */
          
          stroke-dasharray: 0 50;
          fill: transparent;
        }
  
        50% {
          /* 線分50、間隔0の破線にする */
          stroke-dasharray: 50 0;
          stroke-width: .2px;
          fill: transparent;
        }
  
        100% {
          stroke-width: 0;
          fill: g.$color_black;
        }
      }
  }
  

  .spinner {
    margin: 1rem auto 0;
    width: min(10vw, 4em);
    text-align: center;
    stroke-width: .2px;
    stroke: g.$color_black;

  }

  .spinner>div {
    width: .5em;
    height: .5em;
    background-color: g.$color_black;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
      -webkit-transform: scale(0)
    }

    40% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }
}