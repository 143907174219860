// ブレイクポイント
$breakpoints: (
  'xs': 0,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1400px
) !default;

// カラーコード
$color_base: #fefcf0;
$color_main: #d14d41;
$color_white: #fefcf0;
$color_black: #110f0f;
$color_grey: #6f6e68;
$color_lightgrey: #F2F0E5;
$color_link: #4385be;

$color_red: #d14d41;
$color_orange: #da702c;
$color_yellow: #d0a215;
$color_green: #879a39;
$color_cyan: #3aa99f;
$color_blue: #4385be;
$color_purple: #8b7ec8;
$color_magenta: #ce5d97;

// z-index
$zindex_loader: 2000;
$zindex_header: 100;
$zindex_header_hamburger: 99;
$zindex_modal_portfolio: 50; 
$zindex_top_MV_bgImage: -1; 
$zindex_top_about_backLogo: -1; 
$zindex_top_bgLogo: -100; 

// フォントセット
$fontset_base: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
$fontset_heading: 'Roboto','Noto Sans JP', sans-serif;
// $fontset_heading: 'Noto Sans JP', sans-serif;
$fontset_scrollText: 'Roboto', sans-serif;