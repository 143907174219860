@use '../global/' as g;


.modal {
  $duration: .4s;
  $ease: ease-out;

  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(g.$color_black, .9);
  width: 100vw;
  height: 100vh;
  z-index: g.$zindex_modal_portfolio;
  transition-duration: $duration;
  transition-timing-function: $ease;
  transition-property: opacity visibility;

  &.js_show {
    opacity: 1;
    visibility: visible;

    .modal_base {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.js_hide {
    opacity: 0;
    visibility: hidden;

    .modal_base {
      opacity: 0;
      transform: scale(.9);
    }
  }

  &.portfolio {
    background-color: rgba(g.$color_yellow, .9);
  }

  .modal_base {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10%;
    background-color: g.$color_white;
    border-radius: 3vw;
    transition-duration: $duration;
    transition-timing-function: $ease;
    transition-property: opacity transform;

    @include g.mq(max, md) {
      margin: 3%;
      padding: 7%;
    }


    >input[type='button'].modal_closeBtn {
      position: relative;
      background-color: inherit;
      border: 1px solid g.$color_grey;
      border-radius: 2em;
      width: 40%;
      margin: 5% auto -10% auto;
      padding: 1em;
      cursor: pointer;
      font-size: .8em;
      color: g.$color_black;
      letter-spacing: .2em;
      transition: .3s;
      
      @include g.mq(max, md) {
        margin: 10% auto 0% auto;
      }


      &:hover {
        background-color: g.$color_lightgrey;
      }
    }

    img {
      margin-bottom: 2em;
    }

    .info {

      display: flex;
      justify-content: space-between;

      @include g.mq(max, md) {
        flex-direction: column;
      }

      >.title {
        font-size: 1.2em;
      }

      >a.url {
        color: g.$color_blue;
        text-decoration: underline;

        >svg {
          padding-right: .3em;
        }
      }

      .tags {
        display: flex;
        justify-content: end;
        font-weight: bold;
        line-height: 1.9em;
        
        @include g.mq(max, md) {
          justify-content: start;
          font-size: .7em;
          margin-top: 1em;
        }

        >li {
          margin-left: 0.5em;
          padding: 0 .7em;
          border-width: 2px;
          border-style: solid;
          border-radius: .5em;
        }

        >li.category {
          margin-right: auto;
          background-color: g.$color_grey;
          margin-left: 0;
          color: g.$color_white;

          @include g.mq(max, md) {
            margin-right: unset;
          }
        }

        >li.design {
          border-color: g.$color_cyan;
          color: g.$color_cyan;
        }

        >li.coading {
          border-color: g.$color_magenta;
          color: g.$color_magenta;
        }
      }
    }


  }
}