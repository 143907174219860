@use "../global" as g;


body.portfolio {
  --mainColor: #{g.$color_yellow};


  .mainVisual {
    background-color: var(--mainColor);
    overflow: hidden;
    background-image: url('../img/portfolio_mainimage.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;


    .container {
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: center;



      .heading_main {
        color: g.$color_white;
      }
    }

  }

  .achieve {
    --sectionColor: #{g.$color_yellow};

    .container {
      margin-top: -5vh;
      padding-bottom: 10vh;


      >ul {
        display: flex;
        justify-content: start;
        align-items: stretch;
        flex-wrap: wrap;
        max-width: map-get(g.$breakpoints, xxl);
        margin-right: auto;
        margin-left: auto;

        @include g.mq(max, md) {
          flex-direction: column;
        }

        >li {
          width: 32%;
          margin-right: 2%;
          display: flex;
          flex-direction: column;
          margin-bottom: 2em;

          &:nth-of-type(3n) {
            margin-right: unset;
          }

          @include g.mq(max, md) {
            width: auto;
            margin-bottom: 2em;
          }

          >a {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: g.$color_lightgrey;
            border-radius: 8px;
            padding: 28px 28px 18px;
            transition: background-color .3s;


            >img {
              width: 100%;
              height: auto;
              margin-top: auto;
              margin-bottom: auto;
              padding-bottom: .7em;
              transition: transform .3s;
            }

            .tags {
              display: flex;
              justify-content: end;
              margin-top: .5em;
              font-size: .6em;
              font-weight: bold;
              line-height: 1.8em;

              >li {
                margin-left: 0.5em;
                padding: 0 .5em;
                border-radius: .5em;
              }

              >li.category {
                margin-right: auto;
                background-color: #b7b5ac;
                margin-left: 0;
                color: g.$color_white;
              }

              >li.design {
                border: 1px solid g.$color_cyan;
                color: g.$color_cyan;
              }

              >li.coading {
                border: 1px solid g.$color_magenta;
                color: g.$color_magenta;
              }
            }

            &:hover {
              background-color: darken(g.$color_lightgrey, 10%);

              >img {
                transform: scale(1.03);
              }
            }
          }
        }
      }
    }
  }
}