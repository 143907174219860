@use "../global" as g;


body.contact {
  --mainColor: #{g.$color_blue};


  .mainVisual {
    background-color: var(--mainColor);
    overflow: hidden;
    background-image: url('../img/contact_mainimage.webp');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;


    .container {
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: center;



      .heading_main {
        color: g.$color_white;
      }
    }

  }

  .itemlist {
    --sectionColor: #{g.$color_cyan};

    .container {
      margin-top: -5vh;
      padding-bottom: 10vh;

      .form_wrapper {
        background-color: g.$color_lightgrey;
        border-radius: 8px;
        padding: 4em 6em;

        @include g.mq(max, md) {
          padding: 2em 1em;
        }

        >p {
          margin-bottom: 2em;
        }

        >form {
          margin: 0 auto;

          >fieldset {
            position: relative;
            margin-bottom: 1em;
            padding: 0;
            border: none;

            >label {
              position: absolute;
              top: 1em;
              left: 1em;
              background-color: g.$color_white;
              border-radius: .5em;
              padding: 0 .7em;
              line-height: 1.7em;
              transition: .3s;
              // transform: translate(1em, 1em);
              color: g.$color_grey;

              &.focus {
                font-weight: bold;
                font-size: .7em;
                top: -1em;
                color: g.$color_white;
                background-color: lighten(g.$color_red, 15%);

              }

              &.completed {
                font-weight: bold;
                font-size: .7em;
                top: -1em;
                left: -1em;
                background-color: g.$color_blue;
                color: g.$color_white;
              }

            }

            >span {
              display: inline-block;
              position: absolute;
              top: 1.3em;
              right: 1em;
              // transform: translateY(-50%);
              font-size: .7em;
              padding: 0 .6em;

              &.req {
                color: g.$color_white;
                background-color: g.$color_red;
                border-radius: .3em;
              }

              &.any {
                color: g.$color_white;
                background-color: g.$color_grey;
                border-radius: .3em;
              }
            }

            >input,
            >textarea {
              width: 100%;
              padding: 1em 3em 1em 1em;
              border: 1px solid g.$color_lightgrey;
              border-radius: .5em;
              background-color: g.$color_white;

              &[type="submit"] {
                $btnStroke: 4px;
                $btnColor: g.$color_red;

                position: relative;
                top: 0;
                background-color: $btnColor;
                color: g.$color_white;
                font-weight: bold;
                cursor: pointer;
                border-bottom: $btnStroke solid darken($btnColor, 13%);
                transition-duration: 0.1s;
                transition-timing-function: ease;
                transition-property: border-bottom, top, margin-bottom;

                &:hover {
                  background-color: lighten($btnColor, 5%);
                  border-bottom-color: darken($btnColor, 8%);
                }

                &:active {
                  top: $btnStroke;
                  margin-bottom: $btnStroke;
                  border-bottom: 0px solid darken($btnColor, 13%);
                }


              }
            }
          }
        }





        >label {
          font-weight: bold;
        }

        >input {
          width: 70%;

          >a {
            color: g.$color_black;
            text-decoration: underline;
          }
        }
      }
    }

  }

  // 確認画面のスタイル
  .contact_confirm {
    >h2 {
      margin-bottom: 1em;
    }

    >p.error {
      color: g.$color_red;
      font-weight: bold;
    }

    dl {
      >dt {
        display: flex;
        align-items: center;
        font-size: .8em;
        font-weight: bold;
        color: g.$color_blue;
        // padding: 0 .7em;

        // border: 1px solid g.$color_grey;
        // border-radius: 3em;

        &::before,
        &::after {
          content: '';
          height: 1px;
          background-color: g.$color_grey;
        }

        &::before {
          flex-basis: 1em;
          margin-right: .3em;
        }

        &::after {
          flex-grow: 1;
          margin-left: 0.3em;
        }
      }

      >dd {
        padding: .3em 1em 2em 1em;
      }
    }

    .confirmBtn {

      display: flex;

      .convexBtn {
        $btnColor: g.$color_grey;
        $textColor: g.$color_white;

        --btnColor: #{$btnColor};
        --btnBorderColor: #{darken($btnColor, 13%)};
        --btnTextColor: #{$textColor};
        --btnStroke: 4px;

        display: block;
        position: relative;
        top: 0;
        flex-grow: 1;
        text-align: center;
        color: var(--btnTextColor);
        padding: 1em;
        border: none;
        border-bottom: var(--btnStroke) solid var(--btnBorderColor);
        border-radius: .5em;
        background-color: var(--btnColor);
        font-weight: bold;
        cursor: pointer;
        transition-duration: 0.1s;
        transition-timing-function: ease;
        transition-property: border-bottom, top, margin-bottom;

        &:hover {
          opacity: .8;
        }

        &:active {
          top: var(--btnStroke);
          margin-bottom: var(--btnStroke);
          border-bottom-width: 0px;
        }

        &.topLinkBtn {
          $btnColor: g.$color_orange;
          --btnColor: #{$btnColor};
          --btnBorderColor: #{darken($btnColor, 13%)};
        }
        &.send {
          $btnColor: g.$color_red;
          --btnColor: #{$btnColor};
          --btnBorderColor: #{darken($btnColor, 13%)};
        }
        &.back {
          $btnColor: g.$color_green;
          --btnColor: #{$btnColor};
          --btnBorderColor: #{darken($btnColor, 13%)};
          margin-right: .5em;
        }
      }

    }
  }
}