$max: 5;
$frac-max: 1;
$default-unit: 'rem';
$group: 'hp_';
$props: (
  m: margin,
  p: padding
);
$directions: (
  t: top,
  b: bottom,
  l: left,
  r: right
);

@for $i from 0 through $max {
  $unit: if($i ==0, '', $default-unit);

  @each $p-short, $p-full in $props {
    .#{$group}#{$p-short}#{$i}e {
      #{$p-full}: #{$i}#{$unit} !important
    }

    @each $d-short, $d-full in $directions {
      .#{$group}#{$p-short}#{$d-short}#{$i}e {
        #{$p-full}-#{$d-full}: #{$i}#{$unit} !important
      }
    }
  }
}

// 0.25単位
@for $i from 0 through $frac-max {
  @for $n from 1 through $max {
    $num: 0.25 * $n;
    $label: if($num ==0.5, '5', $num * 100);

    @each $p-short, $p-full in $props {
      .#{$group}#{$p-short}#{$i}_#{$label}e {
        #{$p-full}: #{$i + $num}#{$default-unit} !important
      }

      @each $d-short, $d-full in $directions {
        .#{$group}#{$p-short}#{$d-short}#{$i}_#{$label}e {
          #{$p-full}-#{$d-full}: #{$i + $num}#{$default-unit} !important
        }
      }
    }
  }

}