@use "../global" as g;

.top {



  .back_logo {
    position: fixed;
    top: 45vh;
    transform: translateY(-50%);
    fill: g.$color_cyan;
    // stroke: g.$color_cyan;
    // stroke-width: .1px;
    // fill: transparent;

    z-index: g.$zindex_top_bgLogo;

    @include g.mq(max, md) {
      width: 160%;
      transform: translate(-30%, -50%);
    }
  }

  .mainVisual {
    height: 100vh;
    clip-path: inset(0);

    .bgImg {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: g.$zindex_top_MV_bgImage;
      width: 100%;
      height: 100vh;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../img/top_main_image.webp');
      background-size: cover;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;

      h2 {
        position: relative;
        font-size: 3.6em;
        line-height: .8em;
        color: g.$color_cyan;
        margin-bottom: .5em;

        @include g.mq(max, md) {
          font-size: 2.6em;
        }

        span {
          display: block;
          font-size: .3em;
        }
      }

      p {
        position: relative;
        color: g.$color_white;
        line-height: 2.6em;
        letter-spacing: .1em;

        @include g.mq(max, md) {
          text-align: left;
          line-height: 2em;
          width: 80vw;

          .br_pc {
            display: none;
          }
        }
      }
    }
  }

  .scrolldown {
    position: relative;
    display: inline-block;
    left: 50%;
    top: -23vh;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 1px;
      height: 25vh;
      background-color: g.$color_white;
      mix-blend-mode: difference;
      animation: scrolldownMove 2s ease-out infinite;
      opacity: 0;
    }

    @keyframes scrolldownMove {
      0% {
        top: 0;
        height: 0;
        opacity: 0;
      }

      20% {
        height: 15.7vh;
        opacity: 1;
      }

      80%,
      100% {
        top: 25vh;
        height: 0;
        opacity: 1;
      }


    }

    span {
      position: absolute;
      top: 25vh;
      width: 3rem;
      height: 3rem;
      transform: translate(-50%, -50%);
      background-color: g.$color_white;
      border: .9rem solid g.$color_black;
      border-radius: 50%;
    }
  }

  .about {
    --sectionColor: #{g.$color_orange};

    .container {
      position: relative;
      overflow: hidden;
      min-height: 100vh;
      padding-top: 20vh;
      padding-bottom: 20vh;

      @include g.mq(max, md) {
        padding-bottom: 0;
      }

      h2 {
        color: var(--sectionColor);
        margin-bottom: 1.5em;
      }

      p {
        text-align: justify;
        width: 40%;
        line-height: 2.2em;

        @include g.mq(max, md) {
          width: 100%;
          line-height: 2em;
        }
      }

      img.sign {
        width: 30%;
        transform: translate(50%, 20%);

        @include g.mq(max, md) {
          width: 70%;
          transform: translateX(30%);
        }
      }

      .location {
        position: absolute;
        z-index: g.$zindex_top_about_backLogo;
        max-width: 70vw;
        max-height: 100vh;
        top: 60%;
        left: 70%;
        transform: translate(-50%, -50%);

        @include g.mq(max, md) {
          position: relative;
          max-width: 130vw;
          bottom: 0;
          left: 0;
          transform: translateX(-20%);
        }

      }
    }
  }

  .achieve {
    --sectionColor: #{g.$color_yellow};

    .container {
      // min-height: 100vh;
      padding-top: 20vh;
      padding-bottom: 20vh;

      .achieve_title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 8em;

        @include g.mq(max, md) {
          flex-direction: column;
          margin-bottom: 5em;
        }

        h2 {
          color: var(--sectionColor);
        }

        .portfolio_link {
          display: inline-block;
          position: relative;
          margin-left: auto;
          font-size: 1em;
          font-weight: bold;
          color: var(--sectionColor);
          border: 1px solid var(--sectionColor);
          border-radius: 3em;
          padding: 1em 2.5em 1em 1.5em;
          transition: 1s;
          

          @include g.mq(max, md) {
            padding: .5em 2em .5em 1em;
          }

          &::after,
          &::before {
            content: '';
            width: .5em;
            height: .5em;
            border-top: solid 1px var(--sectionColor);
            border-right: solid 1px var(--sectionColor);
            position: absolute;
            top: 50%;
            right: 15%;
            will-change: transform;
            transition: all .2s ease-in-out;
          }

          &::before {
            transform: translateX(-300%) translateY(-50%) rotate(45deg);
            opacity: 0;
          }
          &::after {
            transform: translateX(0%) translateY(-50%) rotate(45deg);
            opacity: 1;
          }

          &:hover {
            background-color: rgba(g.$color_yellow, .2);

            &::before {
              transform: translateX(0%) translateY(-50%) rotate(45deg);
              opacity: 1;
            }

            &::after {
              transform: translateX(300%) translateY(-50%) rotate(45deg);
              opacity: 0;
            }
            
          }

        }

      }

      >ul {
        display: flex;
        justify-content: start;
        align-items: stretch;
        flex-wrap: wrap;
        max-width: map-get(g.$breakpoints, xxl);
        margin-right: auto;
        margin-left: auto;

        @include g.mq(max, md) {
          flex-direction: column;
        }

        >li {
          width: 32%;
          margin-right: 2%;
          display: flex;

          &:nth-of-type(3n) {
            margin-right: unset;
          }

          @include g.mq(max, md) {
            width: auto;
            margin-bottom: 2em;
          }

          >a {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #F2F0E5;
            border-radius: 8px;
            padding: 28px 28px 18px;
            transition: background-color .3s;


            >img {
              width: 100%;
              height: auto;
              margin-top: auto;
              margin-bottom: auto;
              transition: transform .3s;
            }
            &:hover {
              background-color: darken(g.$color_lightgrey, 10%);
              >img {
                transform: scale(1.03);
              }
            }

          }
        }
      }
    }
  }
}