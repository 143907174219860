@use "../global" as g;


body.outline {
  --mainColor: #{g.$color_cyan};


  .mainVisual {
    background-color: var(--mainColor);
    overflow: hidden;
    background-image: url('../img/outline_mainimage.webp');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;


    .container {
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: center;



      .heading_main {
        color: g.$color_white;
      }
    }

  }

  .itemlist {
    --sectionColor: #{g.$color_cyan};

    .container {
      margin-top: -5vh;
      padding-bottom: 10vh;

      >dl {
        background-color: g.$color_lightgrey;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        padding: 6em 6em;

        @include g.mq(max, md) {
          flex-direction: column;
          padding: 3em 2em;
        }

        >dt, >dd {
          border-bottom: 1px solid g.$color_grey;
          padding: 2em 1em;


          &:first-of-type {
            border-top: 1px solid g.$color_grey;
          }

          >span {
            display: block;
            font-size: 0.8em;
          }
        }
       


        >dt {
          width: 30%;
          font-weight: bold;
          @include g.mq(max, md) {
            width: auto;
            border-bottom: none;
            padding-top: 1em;
            padding-bottom: 0.3em;
          }
        }

        >dd {
          width: 70%;

          @include g.mq(max, md) {
            width: auto;
            padding-top: 0;
            padding-bottom: 1em;

            &:first-of-type {
              border-top: none;
            }
          }

          >a {
            color: g.$color_black;
            text-decoration: underline;
          }
        }
      }
    }
  }
}