@use "../global" as g;

.heading_main {
  color: g.$color_grey;
  font-size: 3.9em;
  line-height: 1.5em;

  @include g.mq(max, md) {
    font-size: 2em;
  }

  span {
    display: block;
    color: g.$color_black;
    font-size: .29em;
    line-height: 1.2em;

    @include g.mq(max, md) {
      font-size: .5em;
    }
  }
}